import { makeStyles } from '../../../../_snowpack/pkg/@material-ui/core.js';

const conditionalCSS = () =>
  makeStyles((theme) => ({
    navigationBarSpacing: {
      width: '100%',
      height: theme.spacing(8),
    },
    appBarToolBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    leftArea: {
      display: 'flex',
      alignItems: 'center',
    },
    homeAnchor: {
      color: 'white',
      textDecoration: 'none',
    },
    logo: {
      width: 40,
      height: 40,
      background: 'white',
      borderRadius: '50%',
      marginRight: 10,
    },
    title: {
      fontSize: 18,
      fontFamily: 'inherit',
      display: 'flex',
      alignItems: 'center',
    },
    navbarButton: {
      background: theme.palette.getContrastText(theme.palette.primary.main),
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    anchor: {
      textDecoration: 'none',
      color: 'inherit',
    },
    userMenu: {
      width: 250,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 20,
    },
    userEmail: {
      fontSize: 14,
      marginTop: 10,
    },
    userImage: {
      width: 80,
      objectFit: 'cover',
      borderRadius: 40,
      boxShadow: theme.shadows[2],
    },
    logoutButton: {
      borderRadius: 30,
      width: 120,
      marginTop: 10,
    },
  }));

export default conditionalCSS;
