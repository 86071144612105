import { firestore } from '../index.js';

export const getSessionData = async (userID, sessionDetails) => {
  if (sessionDetails) {
    const data = await firestore
      .collection('users')
      .doc(userID)
      .collection('sensorData')
      .where('sessionId', '==', sessionDetails.docId)
      .get()
      .then((snapshots) => snapshots.docs.map((doc) => doc.data()));

    return data;
  }
  return undefined;
};

export const getSessions = (userID) => {
  const sessions = firestore
    .collection('users')
    .doc(userID)
    .collection('sessions')
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => {
        const data = doc.data();
        const { name, sessionId, description } = data;
        return { name, sessionId, docId: doc.id, description };
      }),
    );
  return sessions;
};

export default undefined;
