import { makeStyles } from '../../../../_snowpack/pkg/@material-ui/core.js';

const conditionalCSS = (smallScreen) =>
  makeStyles((theme) => {
    const fabSpacing = smallScreen ? 2 : 5;
    const fabSize = smallScreen ? 40 : 50;
    return {
      optionsContainer: {
        width: '100%',
      },
      settingsAndSensorBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      settingsButton: {
        position: 'fixed',
        bottom: theme.spacing(fabSpacing),
        left: theme.spacing(fabSpacing),
        width: fabSize,
        height: fabSize,
        minHeight: 'unset',
      },
    };
  });

export default conditionalCSS;
