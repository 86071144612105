import { makeStyles } from '../../../../_snowpack/pkg/@material-ui/core.js';

const conditionalCSS = (small) =>
  makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalBackdrop: {
      backgroundColor: 'rgb(0,0,0,0.2)!important',
    },
    settingsPaper: {
      width: small ? '95vw' : '50vw',
      height: '70vh',
      padding: '20px',
    },
    settingsHeadingBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 5,
    },
    settingsDivider: {
      marginBottom: 30,
    },
    listOfSettings: {
      width: 200,
    },
    settingsOption: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 20,
      height: theme.spacing(7),
    },
    settingsOptionTitle: {
      fontWeight: '600',
    },
    menuItemAnchor: {
      color: 'inherit',
      textDecoration: 'none',
      width: '100%',
      height: '100%',
    },
    urlVisibilityButton: {
      minWidth: 0,
      width: 40,
      marginLeft: 10,
    },
    publicUrlField: {
      width: '100%',
      maxWidth: 150,
      minWidth: 0,
    },
  }));

export default conditionalCSS;
