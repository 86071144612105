import React from "../../../../_snowpack/pkg/react.js";
import PropTypes from "../../../../_snowpack/pkg/prop-types.js";
import {Warning} from "../../../../_snowpack/pkg/@material-ui/icons.js";
import {Typography} from "../../../../_snowpack/pkg/@material-ui/core.js";
function NoData({message, classes}) {
  return /* @__PURE__ */ React.createElement("div", {
    className: classes.errorBox
  }, /* @__PURE__ */ React.createElement(Warning, {
    className: classes.errorIcon,
    color: "disabled"
  }), /* @__PURE__ */ React.createElement(Typography, {
    className: classes.errorText,
    color: "textPrimary"
  }, message));
}
NoData.propTypes = {
  message: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    errorBox: PropTypes.string,
    errorIcon: PropTypes.string,
    errorText: PropTypes.string
  }).isRequired
};
export default NoData;
