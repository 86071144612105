import makeStyles from '../../../../_snowpack/pkg/@material-ui/core/styles/makeStyles.js';

const conditionalCSS = () =>
  makeStyles((theme) => ({
    takeParamsContainer: {
      width: '100%',
      height: `calc(100vh - ${theme.spacing(8)}px)`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'fixed',
      zIndex: 9999999,
      background: '#fff',
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
  }));

export default conditionalCSS;
