import { makeStyles } from '../../../../_snowpack/pkg/@material-ui/core.js';

const conditionalCSS = (smallScreen) =>
  makeStyles((theme) => {
    const fabSpacing = smallScreen ? 2 : 5;
    return {
      indicator: {
        position: 'fixed',
        width: 'max-content',
        padding: 10,
        background: theme.palette.third.dark,
        borderRadius: 4,
        bottom: theme.spacing(fabSpacing),
        right: theme.spacing(fabSpacing),
      },
      sensorText: {
        color: theme.palette.getContrastText(theme.palette.third.dark),
        fontWeight: 'bold',
        fontSize: smallScreen ? 14 : 18,
      },
    };
  });

export default conditionalCSS;
