export const SNOWPACK_PUBLIC_API_KEY = "AIzaSyC2HlZOys7x0gRymIF1suXAx6r1mqoZlp0";
export const SNOWPACK_PUBLIC_AUTH_DOMAIN = "eziosense-pm25.firebaseapp.com";
export const SNOWPACK_PUBLIC_DATABASE_URL = "https://eziosense-pm25.firebaseio.com";
export const SNOWPACK_PUBLIC_PROJECT_ID = "eziosense-pm25";
export const SNOWPACK_PUBLIC_STORAGE_BUCKET = "eziosense-pm25.appspot.com";
export const SNOWPACK_PUBLIC_MESSAGING_SENDER_ID = "331989644483";
export const SNOWPACK_PUBLIC_APP_ID = "1:331989644483:web:f5dcdd6db1edb1ebc15e76";
export const SNOWPACK_PUBLIC_MEASUREMENT_ID = "G-5324VDE2HL";
export const SNOWPACK_PUBLIC_GCP_API = "AIzaSyA-69-qDYHawzwlSFXqYNmE4JYRfdDbhQ8";
export const MODE = "production";
export const NODE_ENV = "production";
export const SSR = false;