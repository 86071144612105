import { g as getDefaultExportFromCjs, c as createCommonjsModule } from './_commonjsHelpers-37fa8da4.js';
import { i as interopRequireDefault, _ as _extends_1, d as defaultTheme_1, a as esm } from './defaultTheme-c16963cc.js';

var makeStyles_1 = createCommonjsModule(function (module, exports) {



Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = interopRequireDefault(_extends_1);



var _defaultTheme = interopRequireDefault(defaultTheme_1);

function makeStyles(stylesOrCreator) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (0, esm.makeStyles)(stylesOrCreator, (0, _extends2.default)({
    defaultTheme: _defaultTheme.default
  }, options));
}

var _default = makeStyles;
exports.default = _default;
});

var __pika_web_default_export_for_treeshaking__ = /*@__PURE__*/getDefaultExportFromCjs(makeStyles_1);

export { __pika_web_default_export_for_treeshaking__ as _, makeStyles_1 as m };
