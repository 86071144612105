import { makeStyles } from '../../../../../_snowpack/pkg/@material-ui/core.js';

const conditionalCSS = (medium) =>
  makeStyles(() => ({
    container: {
      padding: '100px 5%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: medium && 'column-reverse',
      alignItems: medium && 'center',
    },
    ezioTagApp: {
      height: 500,
    },
    title: {
      marginTop: 50,
    },
    listContainer: {
      marginTop: 30,
      marginLeft: 10,
    },
    listItem: {
      listStyle: 'none',
    },
    ezioTagPlaystore: {
      width: 200,
      marginTop: 30,
    },
  }));

export default conditionalCSS;
