import React, {useState, useContext} from "../../../../_snowpack/pkg/react.js";
import PropTypes from "../../../../_snowpack/pkg/prop-types.js";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography
} from "../../../../_snowpack/pkg/@material-ui/core.js";
import {Link} from "../../../../_snowpack/pkg/react-router-dom.js";
import {Check, ChevronRight, Edit} from "../../../../_snowpack/pkg/@material-ui/icons.js";
import conditionalCSS from "./styles.js";
import editSessionDetails from "../../../firebase/firestore/editSessionDetails.js";
import {SessionsCtx} from "../../../Wrappers/Sessions/index.js";
function SessionCard({session, userId}) {
  const [sessionName, setSessionName] = useState("");
  const [sessionNameEdit, setSessionNameEdit] = useState(false);
  const [sessionDescription, setSessionDescription] = useState("");
  const [showingSessionDescription, setShowingSessionDescription] = useState(session.description);
  const [sessionDescriptionEdit, setSessionDescriptionEdit] = useState(false);
  const {allSessions, setAllSessions} = useContext(SessionsCtx);
  const classes = conditionalCSS()();
  const updateDescription = () => {
    if (sessionDescription && sessionDescription.length) {
      editSessionDetails({
        userId,
        sessionId: session.docId,
        field: "description",
        value: sessionDescription
      }).then(() => {
        setShowingSessionDescription(sessionDescription);
      });
    }
  };
  const updateSessionName = () => {
    if (sessionName && sessionName.length) {
      const updatedAllSessions = allSessions.map((eachSession) => {
        if (eachSession.docId === session.docId) {
          eachSession.name = sessionName;
        }
        return eachSession;
      });
      editSessionDetails({
        userId,
        sessionId: session.docId,
        field: "name",
        value: sessionName
      }).then(() => setAllSessions(updatedAllSessions));
    }
  };
  return /* @__PURE__ */ React.createElement(Card, {
    className: classes.sessionInfoCard
  }, /* @__PURE__ */ React.createElement(CardContent, {
    className: classes.cardContent
  }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Box, {
    className: [
      !sessionNameEdit ? classes.spaceBetween : "",
      classes.showButtonOnHover
    ].join(" ")
  }, !sessionNameEdit ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h6"
  }, session.name), /* @__PURE__ */ React.createElement(Button, {
    disableElevation: true,
    className: [
      classes.btnRounded,
      classes.buttonWhenHovered
    ].join(" "),
    onClick: () => {
      setSessionNameEdit(true);
    }
  }, /* @__PURE__ */ React.createElement(Edit, {
    color: "action"
  }))) : /* @__PURE__ */ React.createElement(Box, {
    className: classes.spaceBetween
  }, /* @__PURE__ */ React.createElement(TextField, {
    value: sessionName,
    placeholder: session.name,
    onChange: (event) => {
      setSessionName(event.target.value);
    }
  }), /* @__PURE__ */ React.createElement(Button, {
    disableElevation: true,
    color: "primary",
    className: classes.btnRounded,
    variant: "contained",
    onClick: () => {
      updateSessionName();
      setSessionNameEdit(false);
    }
  }, /* @__PURE__ */ React.createElement(Check, null)))), /* @__PURE__ */ React.createElement(Box, {
    className: classes.descriptionContainer
  }, sessionDescriptionEdit ? /* @__PURE__ */ React.createElement(Box, {
    className: classes.spaceBetween
  }, /* @__PURE__ */ React.createElement(TextField, {
    value: sessionDescription,
    onChange: (e) => {
      setSessionDescription(e.target.value);
    },
    placeholder: "Description",
    className: classes.descriptionText
  }), /* @__PURE__ */ React.createElement(Button, {
    className: classes.btnRounded,
    color: "primary",
    variant: "contained",
    disableElevation: true,
    onClick: () => {
      updateDescription();
      setSessionDescriptionEdit(false);
    }
  }, /* @__PURE__ */ React.createElement(Check, null))) : /* @__PURE__ */ React.createElement(Box, {
    className: [
      classes.spaceBetween,
      classes.showButtonOnHover
    ].join(" ")
  }, /* @__PURE__ */ React.createElement(Typography, {
    color: showingSessionDescription ? "textPrimary" : "textSecondary"
  }, showingSessionDescription || "Description"), /* @__PURE__ */ React.createElement(Button, {
    className: [
      classes.btnRounded,
      classes.buttonWhenHovered
    ].join(" "),
    disableElevation: true,
    onClick: () => {
      setSessionDescriptionEdit(true);
    }
  }, /* @__PURE__ */ React.createElement(Edit, {
    color: "action"
  }))))), /* @__PURE__ */ React.createElement(Link, {
    to: `/sessions/${session.sessionId}`,
    className: classes.sessionInfoCardAnchor
  }, /* @__PURE__ */ React.createElement(Button, {
    color: "primary",
    variant: "contained",
    disableElevation: true
  }, /* @__PURE__ */ React.createElement(ChevronRight, null)))));
}
SessionCard.propTypes = {
  session: PropTypes.shape({
    name: PropTypes.string,
    sessionId: PropTypes.string,
    docId: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  userId: PropTypes.string.isRequired
};
export default SessionCard;
