import { c as createCommonjsModule } from './_commonjsHelpers-37fa8da4.js';
import { i as interopRequireDefault, _ as _extends_1, d as defaultTheme_1, a as esm } from './defaultTheme-c16963cc.js';

var withStyles_1 = createCommonjsModule(function (module, exports) {



Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = interopRequireDefault(_extends_1);



var _defaultTheme = interopRequireDefault(defaultTheme_1);

function withStyles(stylesOrCreator, options) {
  return (0, esm.withStyles)(stylesOrCreator, (0, _extends2.default)({
    defaultTheme: _defaultTheme.default
  }, options));
}

var _default = withStyles;
exports.default = _default;
});

export { withStyles_1 as w };
