import React from "../../../../_snowpack/pkg/react.js";
import PropTypes from "../../../../_snowpack/pkg/prop-types.js";
import {Block} from "../../../../_snowpack/pkg/@material-ui/icons.js";
import {Typography, Box} from "../../../../_snowpack/pkg/@material-ui/core.js";
function Forbidden({classes}) {
  return /* @__PURE__ */ React.createElement(Box, {
    className: classes.errorBox
  }, /* @__PURE__ */ React.createElement(Block, {
    className: classes.errorIcon,
    color: "disabled"
  }), /* @__PURE__ */ React.createElement(Typography, {
    className: classes.errorText,
    color: "textPrimary"
  }, "This session is private."));
}
Forbidden.propTypes = {
  classes: PropTypes.shape({
    errorBox: PropTypes.string,
    errorIcon: PropTypes.string,
    errorText: PropTypes.string
  }).isRequired
};
export default Forbidden;
