import {Box, Typography, useMediaQuery} from "../../../../../_snowpack/pkg/@material-ui/core.js";
import React from "../../../../../_snowpack/pkg/react.js";
import conditionalCSS from "./styles.js";
function EziotagDeviceSection() {
  const medium = useMediaQuery("(max-width: 750px)");
  const classes = conditionalCSS(medium)();
  return /* @__PURE__ */ React.createElement(Box, {
    className: classes.container,
    id: "eziotag-section"
  }, /* @__PURE__ */ React.createElement("ol", {
    className: classes.textContainer
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: medium ? "h6" : "h5",
    className: classes.listText
  }, /* @__PURE__ */ React.createElement("li", {
    className: classes.listItem
  }, "Get your EzioTAG")), /* @__PURE__ */ React.createElement(Typography, {
    variant: medium ? "h6" : "h5",
    className: classes.listText
  }, /* @__PURE__ */ React.createElement("li", {
    className: classes.listItem
  }, "Connect With Our App")), /* @__PURE__ */ React.createElement(Typography, {
    variant: medium ? "h6" : "h5",
    className: classes.listText
  }, /* @__PURE__ */ React.createElement("li", {
    className: classes.listItem
  }, "Start Tracking"))), /* @__PURE__ */ React.createElement("img", {
    src: "/dist/assets/images/eziotag-device.png",
    className: classes.deviceImage,
    alt: ""
  }));
}
export default EziotagDeviceSection;
