import Typography from "../../../../_snowpack/pkg/@material-ui/core/Typography.js";
import Box from "../../../../_snowpack/pkg/@material-ui/core/Box.js";
import React from "../../../../_snowpack/pkg/react.js";
import {StyledFirebaseAuth} from "../../../../_snowpack/pkg/react-firebaseui.js";
import {firebaseAuth} from "../../../firebase/index.js";
import uiConfig from "./AuthConfig.js";
import conditionalCSS from "./styles.js";
const Login = () => {
  const classes = conditionalCSS()();
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, {
    className: classes.loginContainer
  }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement("img", {
    src: "/dist/assets/images/logo.png",
    alt: ""
  }), /* @__PURE__ */ React.createElement(Typography, {
    className: classes.subHeading
  }, "Choose what you breathe")), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Typography, {
    className: classes.smallInfo
  }, "Press the button below to proceed"), /* @__PURE__ */ React.createElement(StyledFirebaseAuth, {
    uiConfig,
    firebaseAuth
  }))));
};
export default Login;
