import {Box, Typography, useMediaQuery} from "../../../../../_snowpack/pkg/@material-ui/core.js";
import React from "../../../../../_snowpack/pkg/react.js";
import conditionalCSS from "./styles.js";
function AppDownloadSection() {
  const medium = useMediaQuery("(max-width: 750px)");
  const classes = conditionalCSS(medium)();
  return /* @__PURE__ */ React.createElement(Box, {
    className: classes.container
  }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h4",
    className: classes.title
  }, "EzioTAG Android App"), /* @__PURE__ */ React.createElement("ul", {
    className: classes.listContainer
  }, /* @__PURE__ */ React.createElement("li", {
    className: classes.listItem
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h6"
  }, "Record Sessions")), /* @__PURE__ */ React.createElement("li", {
    className: classes.listItem
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h6"
  }, "See real time AQI indexes")), /* @__PURE__ */ React.createElement("li", {
    className: classes.listItem
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h6"
  }, "Share and view Sessions"))), /* @__PURE__ */ React.createElement("a", {
    href: "https://play.google.com/store/apps/details?id=in.aerogram.eziosense.pm2_5",
    target: "_blank",
    rel: "noreferrer"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/dist/assets/images/google-play-badge.png",
    alt: "",
    className: classes.ezioTagPlaystore
  }))), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement("img", {
    src: "/dist/assets/images/eziotag-app.png",
    alt: "",
    className: classes.ezioTagApp
  })));
}
export default AppDownloadSection;
