import {Box, Card, Typography} from "../../../../_snowpack/pkg/@material-ui/core.js";
import React from "../../../../_snowpack/pkg/react.js";
import PropTypes from "../../../../_snowpack/pkg/prop-types.js";
import levels from "./levels.js";
import conditionalCSS from "./styles.js";
const LevelIndicator = ({color, value}) => {
  const classes = conditionalCSS()();
  return /* @__PURE__ */ React.createElement(Box, {
    className: classes.colorBox
  }, /* @__PURE__ */ React.createElement("div", {
    className: classes.color,
    style: {background: color}
  }), /* @__PURE__ */ React.createElement(Typography, {
    className: classes.colorText
  }, value));
};
const PollutionIndicators = ({sensor}) => {
  const classes = conditionalCSS()();
  return /* @__PURE__ */ React.createElement(Card, {
    className: classes.colorCard
  }, Object.keys(levels[sensor]).map((eachLevel, index) => {
    const level = levels[sensor][eachLevel];
    return /* @__PURE__ */ React.createElement(LevelIndicator, {
      color: level.color,
      value: `${level.text} - ${level.value}`,
      key: `${index * 3}_level-indicator`
    });
  }));
};
LevelIndicator.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};
PollutionIndicators.propTypes = {
  sensor: PropTypes.string.isRequired
};
export default PollutionIndicators;
