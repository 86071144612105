import levels from '../components/helpers/PollutionIndicators/levels.js';

const BaseUrl = '/assets/images/markers';

const getMarkerIcon = (pollutionValue, sensor) => {
  if (!pollutionValue) {
    return undefined;
  }
  const sensorLevels = Object.keys(levels[sensor]);
  for (let i = 0; i < sensorLevels.length; i += 1) {
    const currentLevel = sensorLevels[i];
    // const nextLevel = sensorLevels[i + 1];
    if (pollutionValue < levels[sensor][currentLevel].value) {
      return {
        url: BaseUrl + levels[sensor][currentLevel].url,
        contrastText: levels[sensor][currentLevel].contrastText,
      };
    }
  }
  return {
    url: '',
    contrastText: '',
  };
};

export default getMarkerIcon;
