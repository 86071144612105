import React, {useContext, useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import {useParams} from "../../../../_snowpack/pkg/react-router-dom.js";
import {
  getSessionData,
  getSessions
} from "../../../firebase/firestore/getSessions.js";
import {AuthContext} from "../../../Wrappers/Authentication/index.js";
import {SessionsCtx} from "../../../Wrappers/Sessions/index.js";
import GoogleMap from "../../Elements/GoogleMap/index.js";
import Navigation from "../../Elements/Navigation/index.js";
function SingleSession() {
  const routeParams = useParams();
  const {allSessions, setAllSessions} = useContext(SessionsCtx);
  const [singleSession, setSingleSession] = useState();
  const {user} = useContext(AuthContext);
  useEffect(() => {
    (async () => {
      let sessions;
      if (!allSessions) {
        sessions = await getSessions(user.uid);
        setAllSessions(sessions);
      }
      sessions = allSessions;
      const currentSession = sessions?.filter((session) => session.sessionId === routeParams.sessionId)[0];
      const sessionData = await getSessionData(user.uid, currentSession);
      setSingleSession(sessionData);
    })();
  }, [routeParams, user.uid, setAllSessions, allSessions]);
  return /* @__PURE__ */ React.createElement("div", {
    style: {
      overflow: "hidden",
      width: "100%",
      height: "100vh",
      position: "relative"
    }
  }, /* @__PURE__ */ React.createElement(Navigation, null), singleSession ? /* @__PURE__ */ React.createElement(GoogleMap, {
    data: singleSession,
    onMapLoaded: () => {
    },
    deleteDataFromMemory: () => {
    }
  }) : null);
}
export default SingleSession;
