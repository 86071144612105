import { firestore } from '../index.js';

export const changeSessionVisibility = (isVisible, userId, sessionId) => {
  firestore
    .collection('users')
    .doc(userId)
    .collection('sessions')
    .doc(sessionId)
    .update({ isVisible });
};
export const getSessionVisibility = (sessionId, userId) =>
  firestore
    .collection('users')
    .doc(userId)
    .collection('sessions')
    .doc(sessionId)
    .get()
    .then((snapshot) => {
      const { isVisible } = snapshot.data();
      return isVisible;
    });
export default undefined;
