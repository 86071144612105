import { makeStyles } from '../../../../../_snowpack/pkg/@material-ui/core.js';

const conditionalCSS = (medium) =>
  makeStyles((theme) => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: `${medium ? '50px' : '100px'} 5%`,
      background: theme.palette.background.default,
      alignItems: 'center',
      flexDirection: medium && 'column-reverse',
    },
    textContainer: {
      width: !medium ? '50%' : '80%',
      marginLeft: 20,
    },
    deviceImage: {
      width: medium && 200,
      marginBottom: medium && 30,
    },
    listText: {
      margin: `0px 0px ${medium ? '10px' : '30px'} 0px`,
      '&:last-child': {
        margin: 0,
      },
    },
  }));

export default conditionalCSS;
