import * as __SNOWPACK_ENV__ from '../../../../_snowpack/env.js';

import React, {useContext, useEffect, useRef, useState} from "../../../../_snowpack/pkg/react.js";
import {
  GoogleMap as ReactGoogleMaps,
  Marker,
  useJsApiLoader,
  InfoWindow
} from "../../../../_snowpack/pkg/@react-google-maps/api.js";
import {Box, Typography} from "../../../../_snowpack/pkg/@material-ui/core.js";
import PropTypes from "../../../../_snowpack/pkg/prop-types.js";
import getMarkerIcon from "../../../functions/getMarkerIcon.js";
import {SensorsCtx} from "../../../Wrappers/Sensor/index.js";
import conditionalCSS from "./styles.js";
import MapOptionsCard from "../../helpers/MapOptionsCard/index.js";
import PollutionIndicators from "../../helpers/PollutionIndicators/index.js";
function GoogleMap({data}) {
  const {isLoaded} = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_GCP_API
  });
  const classes = conditionalCSS()();
  const [tooltip, setTooltip] = useState();
  const {currentSensor} = useContext(SensorsCtx);
  const map = useRef();
  const [mapMarkers, setMapMarkers] = useState([]);
  const onLoad = React.useCallback((loadedMap) => {
    const bounds = new window.google.maps.LatLngBounds();
    loadedMap.fitBounds(bounds);
    map.current = loadedMap;
    map.current.panTo(data && data[0]);
  }, [data]);
  useEffect(() => {
    setMapMarkers(data);
  }, [data]);
  const onUnmount = React.useCallback(() => {
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    style: {
      overflow: "hidden",
      width: "100%",
      height: "100%",
      position: "relative"
    }
  }, isLoaded ? /* @__PURE__ */ React.createElement(ReactGoogleMaps, {
    mapContainerClassName: classes.map,
    zoom: 10,
    onLoad,
    onUnmount,
    options: {
      minZoom: 3,
      streetViewControl: false,
      zoomControl: false
    },
    center: mapMarkers && mapMarkers[0]
  }, mapMarkers.map((point, index) => {
    const markerInfo = getMarkerIcon(point[currentSensor], currentSensor);
    if (!markerInfo) {
      return null;
    }
    return /* @__PURE__ */ React.createElement(Marker, {
      key: `${index * 23}_marker`,
      position: {lat: point.lat, lng: point.lng},
      icon: markerInfo.url,
      onClick: () => {
        setTooltip({
          value: point[currentSensor],
          lat: point.lat,
          lng: point.lng,
          img: markerInfo.url,
          contrastText: markerInfo.contrastText
        });
      }
    });
  }), tooltip && /* @__PURE__ */ React.createElement(InfoWindow, {
    position: tooltip,
    onCloseClick: () => setTooltip()
  }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Box, {
    className: classes.tooltipPointValueContainer
  }, /* @__PURE__ */ React.createElement("img", {
    src: tooltip.img,
    className: classes.tooltipPointIcon,
    alt: ""
  }), /* @__PURE__ */ React.createElement(Typography, {
    className: classes.tooltipPointValue,
    style: {color: tooltip.contrastText}
  }, tooltip.value)), /* @__PURE__ */ React.createElement(Typography, {
    className: classes.positionText
  }, "Lat:", tooltip.lat), /* @__PURE__ */ React.createElement(Typography, {
    className: classes.positionText
  }, "Lng:", tooltip.lng)))) : /* @__PURE__ */ React.createElement(React.Fragment, null), /* @__PURE__ */ React.createElement(PollutionIndicators, {
    sensor: currentSensor
  }), /* @__PURE__ */ React.createElement(MapOptionsCard, null));
}
GoogleMap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
export default React.memo(GoogleMap);
