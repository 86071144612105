import {Box, Typography} from "../../../../_snowpack/pkg/@material-ui/core.js";
import React from "../../../../_snowpack/pkg/react.js";
import PropTypes from "../../../../_snowpack/pkg/prop-types.js";
import conditionalCSS from "./styles.js";
import sensorTypes from "../../../constants/sensors.js";
function SensorIndicator({sensor, smallScreen}) {
  const classes = conditionalCSS(smallScreen)();
  return /* @__PURE__ */ React.createElement(Box, {
    className: classes.indicator,
    elevation: 0
  }, /* @__PURE__ */ React.createElement(Typography, {
    className: classes.sensorText
  }, sensorTypes[sensor]));
}
SensorIndicator.propTypes = {
  sensor: PropTypes.string.isRequired,
  smallScreen: PropTypes.bool.isRequired
};
export default SensorIndicator;
