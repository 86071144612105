function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  textArea.style.zIndex = '-1000';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    return false;
  }
  document.body.removeChild(textArea);
  return true;
}
function copyTextToClipboard(text, onSuccess, onError) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(onSuccess, onError);
}
const webShare = (text, url, onSuccess, onError) => {
  if (navigator.share) {
    navigator
      .share({
        url: !url ? text : url,
        text,
      })
      .catch(() => {
        copyTextToClipboard(text, onSuccess, onError);
      });
  } else {
    copyTextToClipboard(text, onSuccess, onError);
  }
};

export default webShare;
