import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Input,
  TextField
} from "../../../../_snowpack/pkg/@material-ui/core.js";
import React, {useState, useEffect, useContext} from "../../../../_snowpack/pkg/react.js";
import PropTypes from "../../../../_snowpack/pkg/prop-types.js";
import {Redirect} from "../../../../_snowpack/pkg/react-router-dom.js";
import qs from "../../../../_snowpack/pkg/qs.js";
import {AccountCircle} from "../../../../_snowpack/pkg/@material-ui/icons.js";
import conditionalCSS from "./styles.js";
import {AuthContext} from "../../../Wrappers/Authentication/index.js";
const TakeParams = ({params}) => {
  const classes = conditionalCSS()();
  const [userID, setUserID] = useState("");
  const [sessionID, setSessionID] = useState("");
  const [redirectionURL, setRedirectionURL] = useState();
  const [invalidFields, setInvalidField] = useState(true);
  const {user, authenticated} = useContext(AuthContext);
  useEffect(() => {
    setUserID(!params.user ? "" : params.user);
    setSessionID(!params.session ? "" : params.session);
  }, [params]);
  const handleEnter = (event) => {
    event.preventDefault();
    if (userID.length && sessionID.length) {
      setRedirectionURL(`/public${qs.stringify({user: userID, session: sessionID}, {addQueryPrefix: true})}`);
    }
  };
  useEffect(() => {
    if (!userID.length || !sessionID.length) {
      setInvalidField(true);
    } else {
      setInvalidField(false);
    }
  }, [userID, sessionID]);
  if (redirectionURL) {
    return /* @__PURE__ */ React.createElement(Redirect, {
      to: redirectionURL
    });
  }
  return /* @__PURE__ */ React.createElement(Box, {
    className: classes.takeParamsContainer
  }, /* @__PURE__ */ React.createElement("form", {
    action: "#",
    className: classes.inputContainer,
    onSubmit: handleEnter
  }, /* @__PURE__ */ React.createElement(FormControl, {
    variant: "standard"
  }, /* @__PURE__ */ React.createElement(InputLabel, {
    htmlFor: "outlined-adornment-password"
  }, "User ID"), /* @__PURE__ */ React.createElement(Input, {
    color: "primary",
    id: "outlined-adornment-password",
    value: userID,
    onChange: (event) => setUserID(event.target.value),
    autoComplete: "off",
    endAdornment: authenticated ? /* @__PURE__ */ React.createElement(InputAdornment, {
      position: "end",
      onClick: () => setUserID(user.uid)
    }, /* @__PURE__ */ React.createElement(IconButton, {
      edge: "end",
      title: "Insert Your User ID"
    }, /* @__PURE__ */ React.createElement(AccountCircle, null))) : null
  })), /* @__PURE__ */ React.createElement(TextField, {
    variant: "standard",
    value: sessionID,
    label: "Session ID",
    onChange: (event) => setSessionID(event.target.value)
  }), /* @__PURE__ */ React.createElement(Button, {
    variant: "contained",
    color: "primary",
    type: "submit",
    disabled: invalidFields
  }, "Enter")));
};
TakeParams.propTypes = {
  params: PropTypes.shape({
    user: PropTypes.string,
    session: PropTypes.string
  })
};
TakeParams.defaultProps = {
  params: {}
};
export default TakeParams;
