import React from "../../../../_snowpack/pkg/react.js";
import Navigation from "../../Elements/Navigation/index.js";
import AppDownloadSection from "./AppDownloadSection/index.js";
import EziotagDeviceSection from "./EziotagDeviceSection/index.js";
import LandingSection from "./LandingSection/index.js";
import WebViewerSection from "./WebViewerSection/index.js";
function Home() {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Navigation, null), /* @__PURE__ */ React.createElement(LandingSection, null), /* @__PURE__ */ React.createElement(EziotagDeviceSection, null), /* @__PURE__ */ React.createElement(AppDownloadSection, null), /* @__PURE__ */ React.createElement(WebViewerSection, null));
}
export default Home;
