import React, {useContext, useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
  useMediaQuery
} from "../../../../_snowpack/pkg/@material-ui/core.js";
import PropTypes from "../../../../_snowpack/pkg/prop-types.js";
import {useParams, useHistory} from "../../../../_snowpack/pkg/react-router-dom.js";
import {Close, Share} from "../../../../_snowpack/pkg/@material-ui/icons.js";
import QueryString from "../../../../_snowpack/pkg/qs.js";
import {withSnackbar} from "../../../../_snowpack/pkg/notistack.js";
import conditionalCSS from "./styles.js";
import {SessionsCtx} from "../../../Wrappers/Sessions/index.js";
import {SensorsCtx} from "../../../Wrappers/Sensor/index.js";
import sensorTypes from "../../../constants/sensors.js";
import {
  changeSessionVisibility,
  getSessionVisibility
} from "../../../firebase/firestore/sessionVisibility.js";
import {AuthContext} from "../../../Wrappers/Authentication/index.js";
import webShare from "../../../functions/webShare.js";
function SessionSettings({show, onClose, publicRoute, enqueueSnackbar}) {
  const small = useMediaQuery("(max-width: 690px)");
  const classes = conditionalCSS(small)();
  const {allSessions} = useContext(SessionsCtx);
  const {currentSensor, setCurrentSensor} = useContext(SensorsCtx);
  const {user} = useContext(AuthContext);
  const [currentSessionName, setCurrentSessionName] = useState();
  const [sessionVisibility, setSessionVisibility] = useState(void 0);
  const [publicSessionUrl, setPublicSessionUrl] = useState("");
  const params = useParams();
  const history = useHistory();
  const share = () => {
    const success = () => {
      enqueueSnackbar("Copied to clipboard!", {
        variant: "success",
        autoHideDuration: 1e3
      });
    };
    const error = () => {
      enqueueSnackbar("Failed to share/copy, please update your browser!", {
        variant: "error",
        autoHideDuration: 1e3
      });
    };
    webShare(publicSessionUrl, void 0, success, error);
  };
  useEffect(() => {
    if (allSessions) {
      allSessions.forEach((session) => {
        if (session.sessionId === params.sessionId) {
          setCurrentSessionName(session);
        }
      });
    }
  }, [allSessions, params]);
  useEffect(() => {
    if (allSessions) {
      const currentSession = allSessions.find((session) => {
        if (params.sessionId === session.sessionId) {
          return params;
        }
        return void 0;
      });
      if (currentSession) {
        setCurrentSessionName(currentSession);
      }
    }
  }, [params, allSessions]);
  useEffect(() => {
    if (user && user.uid && currentSessionName) {
      getSessionVisibility(currentSessionName.docId, user.uid).then((isVisible) => {
        setSessionVisibility(isVisible ? 1 : 0);
      });
    }
  }, [currentSessionName, user]);
  useEffect(() => {
    if (user && currentSessionName) {
      const {origin} = window.location;
      setPublicSessionUrl(`${origin}/public${QueryString.stringify({
        user: user.uid,
        session: currentSessionName.docId
      }, {addQueryPrefix: true})}`);
    }
  }, [user, currentSessionName]);
  return /* @__PURE__ */ React.createElement(Modal, {
    open: show,
    className: classes.modal,
    BackdropProps: {className: classes.modalBackdrop}
  }, /* @__PURE__ */ React.createElement(Paper, {
    className: classes.settingsPaper
  }, /* @__PURE__ */ React.createElement(Box, {
    className: classes.settingsHeadingBox
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h5"
  }, "Settings"), /* @__PURE__ */ React.createElement(Button, null, /* @__PURE__ */ React.createElement(Close, {
    onClick: onClose
  }))), /* @__PURE__ */ React.createElement(Divider, {
    className: classes.settingsDivider
  }), !publicRoute ? /* @__PURE__ */ React.createElement(Box, {
    className: classes.settingsOption
  }, /* @__PURE__ */ React.createElement(Typography, {
    className: classes.settingsOptionTitle
  }, "Session"), /* @__PURE__ */ React.createElement(Select, {
    value: currentSessionName?.sessionId || "",
    variant: "outlined"
  }, allSessions && allSessions.map((session, index) => /* @__PURE__ */ React.createElement(MenuItem, {
    key: `${index * 4}_settings-item`,
    value: session.sessionId,
    onClick: () => {
      history.push(`/sessions/${session.sessionId}`);
    }
  }, session.name.length >= 20 ? `${session.name.substr(0, 20)}...` : session.name)))) : null, /* @__PURE__ */ React.createElement(Box, {
    className: classes.settingsOption
  }, /* @__PURE__ */ React.createElement(Typography, {
    className: classes.settingsOptionTitle
  }, "Sensor Type"), /* @__PURE__ */ React.createElement(Select, {
    value: currentSensor,
    variant: "outlined",
    onChange: (event) => {
      setCurrentSensor(event.target.value);
    }
  }, Object.keys(sensorTypes).map((sensorKey, index) => /* @__PURE__ */ React.createElement(MenuItem, {
    key: `${index * 4}_sensor-item`,
    value: sensorKey
  }, sensorTypes[sensorKey])))), !publicRoute ? /* @__PURE__ */ React.createElement(Box, {
    className: classes.settingsOption
  }, /* @__PURE__ */ React.createElement(Typography, {
    className: classes.settingsOptionTitle
  }, "Session Visibility"), /* @__PURE__ */ React.createElement(Box, null, sessionVisibility === 1 ? /* @__PURE__ */ React.createElement(Button, {
    color: "secondary",
    disableElevation: true,
    variant: "contained",
    className: classes.urlVisibilityButton,
    onClick: share
  }, /* @__PURE__ */ React.createElement(Share, null)) : null, /* @__PURE__ */ React.createElement(Select, {
    value: sessionVisibility === void 0 ? "" : sessionVisibility,
    variant: "outlined",
    onChange: (e) => {
      setSessionVisibility(e.target.value);
      changeSessionVisibility(Boolean(e.target.value), user.uid, currentSessionName.docId);
    }
  }, /* @__PURE__ */ React.createElement(MenuItem, {
    value: 0
  }, "Private"), /* @__PURE__ */ React.createElement(MenuItem, {
    value: 1
  }, "Public")))) : null));
}
SessionSettings.propTypes = {
  show: PropTypes.bool.isRequired,
  publicRoute: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};
SessionSettings.defaultProps = {
  publicRoute: true
};
export default withSnackbar(SessionSettings);
