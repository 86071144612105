import { makeStyles } from '../../../../_snowpack/pkg/@material-ui/core.js';

const conditionalCSS = () =>
  makeStyles((theme) => ({
    map: {
      width: '100%',
      height: `calc(100vh - ${theme.spacing(8)}px)`,
    },
    tooltipPointValueContainer: {
      position: 'relative',
      margin: '0px auto',
      width: 50,
      height: 50,
      marginBottom: 10,
    },
    tooltipPointIcon: {
      width: '100%',
      height: '100%',
    },
    tooltipPointValue: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      fontWeight: 'bold',
      fontSize: 18,
    },
    positionText: {
      textAlign: 'center',
      fontSize: 12,
      marginBottom: 5,
    },
  }));

export default conditionalCSS;
