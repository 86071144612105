import makeStyles from '../../../../../_snowpack/pkg/@material-ui/core/styles/makeStyles.js';

const conditionalCSS = (hideOneSessionCardQuery, mdDown) =>
  makeStyles((theme) => ({
    container: {
      background: theme.palette.background.default,
      padding: '50px 5%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: hideOneSessionCardQuery && 'column-reverse',
      alignItems: hideOneSessionCardQuery && 'center',
    },
    sessionsImage: {
      width: '30vw',
    },
    textContainer: {
      marginTop: 70,
    },
    subTitle: {
      marginTop: 20,
    },
    stepsToConnectWithAppBox: {
      marginTop: 20,
    },
    stepsItem: {
      listStyle: 'none',
      margin: '5px 0px',
      marginLeft: 10,
    },
    loginAnchor: {
      textDecoration: 'none',
      color: 'inherit',
      width: '100%',
      height: '100%',
    },
    loginBtn: {
      margin: '20px 10px',
    },
    sessionCardContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    sessionsCardBox: hideOneSessionCardQuery && {
      display: 'flex',
      gap: 20,
    },
    sessionCard: {
      width: 300,
      height: 200,
      margin: '30px 0px',
      userSelect: 'none',
      '&:last-child': mdDown && {
        display: 'none',
      },
    },
    sessionDescription: {
      marginTop: 5,
      color: theme.palette.text.hint,
    },
    sessionActionBtn: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      minWidth: 'unset',
      alignSelf: 'flex-end',
      cursor: 'default',
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
  }));

export default conditionalCSS;
