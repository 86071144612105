import React, {createContext, useState} from "../../../_snowpack/pkg/react.js";
import PropTypes from "../../../_snowpack/pkg/prop-types.js";
export const TooltipCtx = createContext();
function TooltipWrapper({children}) {
  const [tooltip, setTooltip] = useState();
  return /* @__PURE__ */ React.createElement(TooltipCtx.Provider, {
    value: {tooltip, setTooltip}
  }, children);
}
TooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
export default TooltipWrapper;
