import { makeStyles } from '../../../../_snowpack/pkg/@material-ui/core.js';

const conditionalCSS = () =>
  makeStyles((theme) => ({
    container: {
      background: theme.palette.background.default,
      minHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    sessionTitle: {
      padding: '30px 20px 0px',
      fontWeight: 500,
    },
    sessionStatusText: {
      textAlign: 'center',
    },
    sessionInfoCardsContainer: {
      display: 'flex',
      gap: 20,
      padding: '20px 40px',
      flexWrap: 'wrap',
    },
    sessionInfoCardAnchor: {
      textDecoration: 'none',
      alignSelf: 'flex-end',
    },
    sessionInfoCard: {
      width: 300,
      height: 200,
    },
    btnRounded: {
      width: 40,
      minWidth: 'unset',
      height: 40,
      borderRadius: '50%',
    },
    spaceBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    descriptionContainer: {
      marginTop: 5,
    },
    buttonWhenHovered: {
      opacity: 0,
      pointerEvents: 'none',
      transition: 'opacity 0.2s ease-in 0.1s',
    },
    showButtonOnHover: {
      height: 40,
      '&:hover > $buttonWhenHovered': {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
  }));

export default conditionalCSS;
