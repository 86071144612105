const levels = {
  pm2_5: {
    good: {
      value: 50,
      color: '#6EE777',
      text: 'Good',
      url: '/Good.png',
      contrastText: '#000',
    },
    moderate: {
      value: 100,
      color: '#FEEB3C',
      text: 'Moderate',
      url: `/Moderate.png`,
      contrastText: '#000',
    },
    poor: {
      value: 150,
      color: '#F45E25',
      text: 'Poor',
      url: `/Poor.png`,
      contrastText: '#fff',
    },
    unhealthy: {
      value: 200,
      color: '#FF0000',
      text: 'Unhealthy',
      url: `/Unhealthy.png`,
      contrastText: '#fff',
    },
    very_unhealthy: {
      value: 300,
      color: '#AF59BE',
      text: 'Very Unhealthy',
      url: `/Very unhealthy.png`,
      contrastText: '#fff',
    },
    hazardous: {
      value: '300+',
      color: '#62382A',
      text: 'Hazardous',
      url: `/Hazardous.png`,
      contrastText: '#fff',
    },
  },
  pm1_0: {
    good: {
      value: 50,
      color: '#6EE777',
      text: 'Good',
      url: '/Good.png',
      contrastText: '#000',
    },
    moderate: {
      value: 100,
      color: '#FEEB3C',
      text: 'Moderate',
      url: `/Moderate.png`,
      contrastText: '#000',
    },
    poor: {
      value: 150,
      color: '#F45E25',
      text: 'Poor',
      url: `/Poor.png`,
      contrastText: '#fff',
    },
    unhealthy: {
      value: 200,
      color: '#FF0000',
      text: 'Unhealthy',
      url: `/Unhealthy.png`,
      contrastText: '#fff',
    },
    very_unhealthy: {
      value: 300,
      color: '#AF59BE',
      text: 'Very Unhealthy',
      url: `/Very unhealthy.png`,
      contrastText: '#fff',
    },
    hazardous: {
      value: '300+',
      color: '#62382A',
      text: 'Hazardous',
      url: `/Hazardous.png`,
      contrastText: '#fff',
    },
  },
  pm10: {
    good: {
      value: 50,
      color: '#6EE777',
      text: 'Good',
      url: '/Good.png',
      contrastText: '#000',
    },
    moderate: {
      value: 100,
      color: '#FEEB3C',
      text: 'Moderate',
      url: `/Moderate.png`,
      contrastText: '#000',
    },
    poor: {
      value: 150,
      color: '#F45E25',
      text: 'Poor',
      url: `/Poor.png`,
      contrastText: '#fff',
    },
    unhealthy: {
      value: 200,
      color: '#FF0000',
      text: 'Unhealthy',
      url: `/Unhealthy.png`,
      contrastText: '#fff',
    },
    very_unhealthy: {
      value: 300,
      color: '#AF59BE',
      text: 'Very Unhealthy',
      url: `/Very unhealthy.png`,
      contrastText: '#fff',
    },
    hazardous: {
      value: '300+',
      color: '#62382A',
      text: 'Hazardous',
      url: `/Hazardous.png`,
      contrastText: '#fff',
    },
  },
  humidity: {
    cold: {
      value: 20,
      color: '#88c7dc',
      text: 'Cold',
      url: '/Cold.png',
      contrastText: '#000',
    },
    normal: {
      value: 40,
      color: '#FEEB3C',
      text: 'Normal',
      url: `/Normal.png`,
      contrastText: '#000',
    },
    hot: {
      value: 60,
      color: '#BE0000',
      text: 'Hot',
      url: `/Hot.png`,
      contrastText: '#fff',
    },
  },
  temperature: {
    cold: {
      value: 20,
      color: '#88c7dc',
      text: 'Cold',
      url: '/Cold.png',
      contrastText: '#000',
    },
    normal: {
      value: 40,
      color: '#FEEB3C',
      text: 'Normal',
      url: `/Normal.png`,
      contrastText: '#000',
    },
    hot: {
      value: 60,
      color: '#BE0000',
      text: 'Hot',
      url: `/Hot.png`,
      contrastText: '#fff',
    },
  },
};

export default levels;
