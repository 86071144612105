import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

import firebase from '../../_snowpack/pkg/firebase/app.js';
import '../../_snowpack/pkg/firebase/auth.js';
import '../../_snowpack/pkg/firebase/firestore.js';

const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp({
      apiKey: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_API_KEY,
      authDomain: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_AUTH_DOMAIN,
      databaseURL: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_DATABASE_URL,
      projectId: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_PROJECT_ID,
      storageBucket: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_STORAGE_BUCKET,
      messagingSenderId: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_MESSAGING_SENDER_ID,
      appId: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_APP_ID,
    })
  : firebase.app();

export const firebaseAuth = firebaseApp.auth();
export const firestore = firebase.firestore(firebaseApp);
export default firebaseApp;
