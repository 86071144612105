import {useMediaQuery, Box, Fab} from "../../../../_snowpack/pkg/@material-ui/core.js";
import {Settings} from "../../../../_snowpack/pkg/@material-ui/icons.js";
import React, {useContext, useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import {useLocation} from "../../../../_snowpack/pkg/react-router-dom.js";
import {SensorsCtx} from "../../../Wrappers/Sensor/index.js";
import SensorIndicator from "../SensorIndicator/index.js";
import SessionSettings from "../SessionSettings/index.js";
import conditionalCSS from "./styles.js";
function MapOptionsCard() {
  const smallScreen = useMediaQuery("(max-width: 512px)");
  const [showSettings, setShowSettings] = useState(false);
  const [publicRoute, setPublicRoute] = useState(false);
  const {currentSensor} = useContext(SensorsCtx);
  const classes = conditionalCSS(smallScreen)();
  const location = useLocation();
  useEffect(() => {
    const route = location.pathname.toLowerCase();
    if (route === "/public") {
      setPublicRoute(true);
    } else {
      setPublicRoute(false);
    }
  }, [location.pathname]);
  return /* @__PURE__ */ React.createElement(Box, {
    className: classes.optionsContainer
  }, /* @__PURE__ */ React.createElement(Box, {
    className: classes.settingsAndSensorBox
  }, /* @__PURE__ */ React.createElement(Fab, {
    color: "primary",
    className: classes.settingsButton,
    onClick: () => {
      setShowSettings(true);
    }
  }, /* @__PURE__ */ React.createElement(Settings, null)), /* @__PURE__ */ React.createElement(SensorIndicator, {
    smallScreen,
    sensor: currentSensor
  })), /* @__PURE__ */ React.createElement(SessionSettings, {
    show: showSettings,
    onClose: () => {
      setShowSettings(false);
    },
    publicRoute
  }), " ");
}
export default MapOptionsCard;
