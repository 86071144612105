import React from "../../_snowpack/pkg/react.js";
import PropTypes from "../../_snowpack/pkg/prop-types.js";
import MuiWrapper from "./MuiWrappers/index.js";
import Authentication from "./Authentication/index.js";
import SessionsContext from "./Sessions/index.js";
import SensorsContext from "./Sensor/index.js";
import TooltipWrapper from "./Tooltip/index.js";
export default function Wrappers({children}) {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Authentication, null, /* @__PURE__ */ React.createElement(SessionsContext, null, /* @__PURE__ */ React.createElement(SensorsContext, null, /* @__PURE__ */ React.createElement(TooltipWrapper, null, /* @__PURE__ */ React.createElement(MuiWrapper, null, children))))));
}
Wrappers.propTypes = {
  children: PropTypes.node.isRequired
};
