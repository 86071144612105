import React, {useContext} from "../../../_snowpack/pkg/react.js";
import PropTypes from "../../../_snowpack/pkg/prop-types.js";
import {Redirect, Route} from "../../../_snowpack/pkg/react-router-dom.js";
import {AuthContext} from "../Authentication/index.js";
const PrivateRoute = ({children, ...props}) => {
  const {authStatus} = useContext(AuthContext);
  switch (authStatus) {
    case "auth":
      return /* @__PURE__ */ React.createElement(Route, {
        ...props
      }, children);
    case "unauth":
      return /* @__PURE__ */ React.createElement(Redirect, {
        to: `/login?redirect=${props.path}`
      });
    default:
      return null;
  }
};
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired
};
export default PrivateRoute;
