import { makeStyles } from '../../../../_snowpack/pkg/@material-ui/core.js';

const conditionalCSS = () =>
  makeStyles((theme) => {
    const screenHeight = `calc(100vh - ${theme.spacing(16)}px)`;

    return {
      errorContainer: {
        width: '100%',
        height: screenHeight, // 8 + 8 (height of navigation bar + bottom spacing to align in center)
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        zIndex: 9999999,
      },
      loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: screenHeight,
      },
      errorBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: 40,
        alignItems: 'center',
      },
      errorIcon: {
        width: 200,
        height: 200,
      },
      errorText: {
        fontSize: 24,
      },
    };
  });

export default conditionalCSS;
