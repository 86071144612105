import React, {useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import {useLocation} from "../../../../_snowpack/pkg/react-router-dom.js";
import qs from "../../../../_snowpack/pkg/qs.js";
import Navigation from "../../Elements/Navigation/index.js";
import TakeParams from "../../Elements/TakeParams/index.js";
import GoogleMap from "../../Elements/GoogleMap/index.js";
import PublicDataErrors from "../../helpers/PublicDataErrors/index.js";
import getPublicData from "../../../api/getPublicData.js";
function Public() {
  const location = useLocation();
  const params = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [areParamMissing, setAreParamMissing] = useState(true);
  const [request, setRequest] = useState();
  useEffect(() => {
    if (!Object.keys(params).length) {
      setAreParamMissing(true);
    } else if (!params.user || !params.user.length || !params.session || !params.session.length) {
      setAreParamMissing(true);
    } else {
      setAreParamMissing(false);
    }
  }, [params]);
  useEffect(() => {
    if (!areParamMissing) {
      const dataRetrieveReq = (async () => {
        const requestInfo = await getPublicData(params.user, params.session);
        if (requestInfo) {
          const {data, ok} = requestInfo;
          if (!ok) {
            setRequest(data);
            return;
          }
          setRequest({
            status: 200,
            data: JSON.parse(data.response),
            ok
          });
        }
      })();
      return () => {
        if (dataRetrieveReq) {
          Promise.resolve(dataRetrieveReq);
        }
      };
    }
    return void 0;
  }, [params.user, params.session, areParamMissing]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Navigation, null), areParamMissing && /* @__PURE__ */ React.createElement(TakeParams, {
    params
  }), /* @__PURE__ */ React.createElement(PublicDataErrors, {
    code: request?.status,
    message: request?.response,
    isLoading: Boolean(request)
  }), !areParamMissing && request?.ok && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(GoogleMap, {
    data: request?.data
  })));
}
export default Public;
