import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery
} from "../../../../../_snowpack/pkg/@material-ui/core.js";
import {ChevronRight} from "../../../../../_snowpack/pkg/@material-ui/icons.js";
import React from "../../../../../_snowpack/pkg/react.js";
import {Link} from "../../../../../_snowpack/pkg/react-router-dom.js";
import conditionalCSS from "./styles.js";
function WebViewerSection() {
  const hideOneSessionCardQuery = useMediaQuery("(max-width: 1016px)");
  const xsDown = useMediaQuery("(max-width: 850px)");
  const classes = conditionalCSS(hideOneSessionCardQuery, xsDown)();
  return /* @__PURE__ */ React.createElement(Box, {
    className: classes.container
  }, /* @__PURE__ */ React.createElement(Box, {
    className: classes.textContainer
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h4"
  }, "EzioTAG Web"), /* @__PURE__ */ React.createElement(Typography, {
    variant: "h5",
    className: classes.subTitle
  }, "Track your all sessions, manage and edit them."), /* @__PURE__ */ React.createElement(Box, {
    className: classes.stepsToConnectWithAppBox
  }, /* @__PURE__ */ React.createElement(Typography, null, /* @__PURE__ */ React.createElement("li", {
    className: classes.stepsItem
  }, "Login into our android app")), /* @__PURE__ */ React.createElement(Typography, null, /* @__PURE__ */ React.createElement("li", {
    className: classes.stepsItem
  }, "Create a session")), /* @__PURE__ */ React.createElement(Typography, null, /* @__PURE__ */ React.createElement("li", {
    className: classes.stepsItem
  }, "Login into web platform.")), /* @__PURE__ */ React.createElement(Typography, null, /* @__PURE__ */ React.createElement("li", {
    className: classes.stepsItem
  }, "You are ready to track!"))), /* @__PURE__ */ React.createElement(Button, {
    className: classes.loginBtn,
    disableElevation: true,
    size: "large",
    color: "primary",
    variant: "contained"
  }, /* @__PURE__ */ React.createElement(Link, {
    to: "/login",
    tabIndex: "-1",
    className: classes.loginAnchor
  }, "Login Now!"))), /* @__PURE__ */ React.createElement(Box, {
    className: classes.sessionsCardBox
  }, /* @__PURE__ */ React.createElement(Card, {
    className: classes.sessionCard
  }, /* @__PURE__ */ React.createElement(CardContent, {
    className: classes.sessionCardContent
  }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Typography, {
    className: classes.sessionTitle,
    variant: "h6"
  }, "Session"), /* @__PURE__ */ React.createElement(Typography, {
    className: classes.sessionDescription
  }, "Description")), /* @__PURE__ */ React.createElement(Button, {
    tabIndex: "-1",
    color: "primary",
    variant: "contained",
    disableElevation: true,
    className: classes.sessionActionBtn,
    disableRipple: true
  }, /* @__PURE__ */ React.createElement(ChevronRight, null)))), /* @__PURE__ */ React.createElement(Card, {
    className: classes.sessionCard
  }, /* @__PURE__ */ React.createElement(CardContent, {
    className: classes.sessionCardContent
  }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Typography, {
    className: classes.sessionTitle,
    variant: "h6"
  }, "Session"), /* @__PURE__ */ React.createElement(Typography, {
    className: classes.sessionDescription
  }, "Description")), /* @__PURE__ */ React.createElement(Button, {
    color: "primary",
    variant: "contained",
    disableElevation: true,
    className: classes.sessionActionBtn,
    disableRipple: true,
    tabIndex: "-1"
  }, /* @__PURE__ */ React.createElement(ChevronRight, null))))));
}
export default WebViewerSection;
