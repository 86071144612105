import React, {createContext, useEffect, useState} from "../../../_snowpack/pkg/react.js";
import PropTypes from "../../../_snowpack/pkg/prop-types.js";
import {useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import {firebaseAuth} from "../../firebase/index.js";
export const AuthContext = createContext();
const Authentication = ({children}) => {
  const [user, setUser] = useState();
  const history = useHistory();
  const [authenticated, setAuthenticated] = useState();
  const [authStatus, setAuthStatus] = useState("loading");
  useEffect(() => {
    const unsubscriber = firebaseAuth.onAuthStateChanged((authUser) => {
      if (authUser) {
        const details = {
          ...authUser.providerData[0],
          uid: authUser.uid
        };
        setUser(details);
        setAuthenticated(true);
        setAuthStatus("auth");
      } else {
        setUser(false);
        setAuthenticated(false);
        setAuthStatus("unauth");
      }
    });
    return () => {
      unsubscriber();
    };
  }, []);
  const logoutUser = () => {
    firebaseAuth.signOut();
    history.push("/login");
  };
  return /* @__PURE__ */ React.createElement(AuthContext.Provider, {
    value: {
      user,
      authenticated,
      authStatus,
      logoutUser
    }
  }, children);
};
Authentication.propTypes = {
  children: PropTypes.node.isRequired
};
export default Authentication;
