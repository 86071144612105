import React, {createContext, useState} from "../../../_snowpack/pkg/react.js";
import PropTypes from "../../../_snowpack/pkg/prop-types.js";
export const SensorsCtx = createContext();
function SensorsContext({children}) {
  const [currentSensor, setCurrentSensor] = useState("pm2_5");
  return /* @__PURE__ */ React.createElement(SensorsCtx.Provider, {
    value: {currentSensor, setCurrentSensor}
  }, children);
}
SensorsContext.propTypes = {
  children: PropTypes.node.isRequired
};
export default SensorsContext;
