import React from "../../../_snowpack/pkg/react.js";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "../../../_snowpack/pkg/react-router-dom.js";
import Wrappers from "../index.js";
import Public from "../../components/pages/Public/index.js";
import Login from "../../components/pages/Login/index.js";
import Home from "../../components/pages/Home/index.js";
import PrivateRoute from "./PrivateRoute.js";
import Sessions from "../../components/pages/Sessions/index.js";
import SingleSession from "../../components/pages/SingleSession/index.js";
function Routes() {
  return /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(Wrappers, null, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/public",
    exact: true
  }, /* @__PURE__ */ React.createElement(Public, null))), /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/login",
    exact: true
  }, /* @__PURE__ */ React.createElement(Login, null))), /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(PrivateRoute, {
    path: "/sessions",
    exact: true
  }, /* @__PURE__ */ React.createElement(Sessions, null)), /* @__PURE__ */ React.createElement(PrivateRoute, {
    path: "/sessions/:sessionId",
    exact: true
  }, /* @__PURE__ */ React.createElement(SingleSession, null)), /* @__PURE__ */ React.createElement(Route, {
    path: "/",
    exact: true
  }, /* @__PURE__ */ React.createElement(Home, null)))));
}
export default Routes;
