import { makeStyles } from '../../../../_snowpack/pkg/@material-ui/core.js';

const conditionalCSS = () =>
  makeStyles(() => ({
    colorBox: {
      display: 'flex',
      margin: 5,
      width: 200,
    },
    good: {
      background: '#6EE777',
    },
    moderate: {
      background: '#FEEB3C',
    },
    poor: {
      background: '#F45E25',
    },
    unhealthy: {
      background: '#FF0000',
    },
    veryUnhealthy: {
      background: '#AF59BE',
    },
    hazardous: {
      background: '#62382A',
    },
    color: {
      width: 20,
      height: 20,
    },
    colorCard: {
      position: 'absolute',
      top: 'calc(50% - 155px)',
      right: 0,
      transition: 'right 0.2s ease-in',

      '&:not(:hover)': {
        right: '-180px',
      },
    },
    colorText: {
      fontSize: 12,
      marginLeft: 5,
    },
  }));

export default conditionalCSS;
