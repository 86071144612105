import {ThemeProvider} from "../../../_snowpack/pkg/@material-ui/core/styles.js";
import React from "../../../_snowpack/pkg/react.js";
import PropTypes from "../../../_snowpack/pkg/prop-types.js";
import {SnackbarProvider} from "../../../_snowpack/pkg/notistack.js";
import theme from "../../constants/theme.js";
function MuiWrapper({children}) {
  return /* @__PURE__ */ React.createElement(ThemeProvider, {
    theme
  }, /* @__PURE__ */ React.createElement(SnackbarProvider, null, children));
}
MuiWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
export default MuiWrapper;
