import {Box, Typography} from "../../../../_snowpack/pkg/@material-ui/core.js";
import React, {useContext, useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import {Animated} from "../../../../_snowpack/pkg/react-animated-css.js";
import {getSessions} from "../../../firebase/firestore/getSessions.js";
import {AuthContext} from "../../../Wrappers/Authentication/index.js";
import {SessionsCtx} from "../../../Wrappers/Sessions/index.js";
import Navigation from "../../Elements/Navigation/index.js";
import SessionCard from "./card.js";
import conditionalCSS from "./styles.js";
function Sessions() {
  const {user} = useContext(AuthContext);
  const {setAllSessions} = useContext(SessionsCtx);
  const [sessionList, setSessionList] = useState(void 0);
  const classes = conditionalCSS()();
  useEffect(() => {
    const getSessionsAsync = (async () => {
      const sessionData = await getSessions(user.uid);
      setSessionList(sessionData);
      setAllSessions(sessionData);
    })();
    return () => {
      Promise.resolve(getSessionsAsync);
    };
  }, [user, setAllSessions]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Navigation, null), /* @__PURE__ */ React.createElement(Box, {
    className: classes.container
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h4",
    className: classes.sessionTitle
  }, "Sessions"), !sessionList ? /* @__PURE__ */ React.createElement(Typography, {
    variant: "h5",
    className: classes.sessionStatusText
  }, "Loading...") : null, sessionList && !sessionList.length ? /* @__PURE__ */ React.createElement(Typography, {
    variant: "h5"
  }, "No sessions found...") : null, sessionList && /* @__PURE__ */ React.createElement(Animated, {
    animationIn: "fadeInUp",
    animationInDuration: 200
  }, /* @__PURE__ */ React.createElement(Box, {
    className: classes.sessionInfoCardsContainer
  }, sessionList.map((session, index) => /* @__PURE__ */ React.createElement(SessionCard, {
    session,
    userId: user.uid,
    classes,
    key: `${index * 4}_session-card`
  }))))));
}
export default Sessions;
