import { makeStyles } from '../../../../../_snowpack/pkg/@material-ui/core.js';

const conditionalCSS = (medium) =>
  makeStyles((theme) => ({
    landingSectionContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: `calc(100vh - ${theme.spacing(8)}px)`,
      justifyContent: 'space-between',
      padding: '0px 5%',
      position: 'relative',
      overflow: 'hidden',
      flexDirection: medium && 'column-reverse',
      maxHeight: !medium && '600px',
    },
    headingSection: {
      height: '50%',
      marginTop: medium && 30,
    },
    seeHowBtn: {
      padding: '8px 5px 8px 20px',
      marginTop: '30px',
      textTransform: 'unset',
    },
    appSessionTrackedImage: {
      height: medium ? 300 : 500,
    },
    imageContainer: {
      marginTop: medium && 30,
      height: medium ? 300 : 500,
      animation: 'fadeInDown 500ms linear',
    },
    '@keyframes fadeInDown': {
      from: {
        opacity: 0,
        transform: 'translateZ(0px, -2000px, 0px)',
      },
      to: {
        opacity: 1,
        transform: 'translateZ(0px, 0px, 0px)',
      },
    },
  }));

export default conditionalCSS;
