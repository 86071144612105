import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from "../../../../../_snowpack/pkg/@material-ui/core.js";
import {ChevronRight} from "../../../../../_snowpack/pkg/@material-ui/icons.js";
import React from "../../../../../_snowpack/pkg/react.js";
import {Animated} from "../../../../../_snowpack/pkg/react-animated-css.js";
import conditionalCSS from "./styles.js";
function LandingSection() {
  const theme = useTheme();
  const medium = useMediaQuery("(max-width: 750px)");
  const classes = conditionalCSS(medium)();
  const scrollToEzioTAGSection = () => {
    const tag = document.querySelector("#eziotag-section");
    if (!tag)
      return;
    let {top} = tag.getBoundingClientRect();
    top -= theme.spacing(7);
    top += window.scrollY;
    window.scroll(0, top);
  };
  return /* @__PURE__ */ React.createElement(Box, {
    className: classes.landingSectionContainer
  }, /* @__PURE__ */ React.createElement(Box, {
    className: classes.headingSection
  }, /* @__PURE__ */ React.createElement(Animated, {
    animationIn: "fadeInUp",
    animationInDuration: 500,
    animationInDelay: medium ? 1e3 : 0
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h3"
  }, "EzioTAG")), /* @__PURE__ */ React.createElement(Animated, {
    animationIn: "fadeInUp",
    animationInDuration: 700,
    animationInDelay: medium ? 1e3 : 0
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h5"
  }, "Track your PM 2.5 in your hand!")), /* @__PURE__ */ React.createElement(Animated, {
    animationIn: "fadeIn",
    animationInDelay: 300,
    animationInDuration: 400
  }, /* @__PURE__ */ React.createElement(Button, {
    color: "primary",
    variant: "contained",
    size: "large",
    onClick: scrollToEzioTAGSection,
    className: classes.seeHowBtn
  }, "See How", /* @__PURE__ */ React.createElement(ChevronRight, null)))), /* @__PURE__ */ React.createElement(Box, {
    className: classes.imageContainer
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/dist/assets/images/Samsung Galaxy S21 Ultra.png",
    alt: "",
    className: classes.appSessionTrackedImage
  })));
}
export default LandingSection;
