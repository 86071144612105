import QueryString from '../../_snowpack/pkg/qs.js';

const getPublicData = async (userId, sessionId) => {
  if (userId && sessionId) {
    const getSessionDataURL = `https://us-central1-eziosense-pm25.cloudfunctions.net/getSessionData${QueryString.stringify(
      { userId, sessionId },
      { addQueryPrefix: true },
    )}`;
    const request = await fetch(getSessionDataURL, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    });

    let data;
    if (request.status === 200) {
      data = await request.json();
    } else {
      data = JSON.parse(await request.text());
    }
    return { ok: request.ok, data };
  }
  return undefined;
};

export default getPublicData;
