import React from "../../../../_snowpack/pkg/react.js";
import PropTypes from "../../../../_snowpack/pkg/prop-types.js";
import {Box, Typography} from "../../../../_snowpack/pkg/@material-ui/core.js";
import Forbidden from "./Forbidden.js";
import NoData from "./NoData.js";
import conditionalCSS from "./styles.js";
function PublicDataErrors({code, message, isLoading}) {
  const classes = conditionalCSS()();
  if (code !== 200 && !isLoading) {
    return /* @__PURE__ */ React.createElement("div", {
      className: classes.errorContainer
    }, code === 403 ? /* @__PURE__ */ React.createElement(Forbidden, null) : null, code === 404 ? /* @__PURE__ */ React.createElement(NoData, {
      message,
      classes
    }) : null, code && !isLoading && code !== 200 ? /* @__PURE__ */ React.createElement(NoData, {
      message: code,
      classes
    }) : null);
  }
  if (isLoading && code !== 200) {
    return /* @__PURE__ */ React.createElement(Box, {
      className: classes.loadingContainer
    }, /* @__PURE__ */ React.createElement(Typography, {
      variant: "h4"
    }, "Loading..."));
  }
  if (code === 200) {
    return null;
  }
}
PublicDataErrors.propTypes = {
  code: PropTypes.number,
  message: PropTypes.string,
  isLoading: PropTypes.bool
};
PublicDataErrors.defaultProps = {
  code: void 0,
  message: void 0,
  isLoading: true
};
export default PublicDataErrors;
