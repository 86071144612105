import React, {createContext, useState} from "../../../_snowpack/pkg/react.js";
import PropTypes from "../../../_snowpack/pkg/prop-types.js";
export const SessionsCtx = createContext();
function SessionsContext({children}) {
  const [allSessions, setAllSessions] = useState(void 0);
  return /* @__PURE__ */ React.createElement(SessionsCtx.Provider, {
    value: {allSessions, setAllSessions}
  }, children);
}
SessionsContext.propTypes = {
  children: PropTypes.node.isRequired
};
export default SessionsContext;
