import { createTheme } from '../../_snowpack/pkg/@material-ui/core.js';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3498db',
      dark: '#2980b9',
    },
    secondary: {
      main: '#ffffff',
      light: '#ffffff',
      dark: '#fdfdfd',
    },
    third: {
      light: '#e33371',
      main: '#dc004e',
      dark: '#9a0036',
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
  },
});

export default theme;
