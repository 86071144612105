import React, {useContext, useState} from "../../../../_snowpack/pkg/react.js";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Menu,
  Toolbar,
  Typography
} from "../../../../_snowpack/pkg/@material-ui/core.js";
import {Link, useLocation} from "../../../../_snowpack/pkg/react-router-dom.js";
import conditionalCSS from "./styles.js";
import {AuthContext} from "../../../Wrappers/Authentication/index.js";
const Navigation = () => {
  const classes = conditionalCSS()();
  const {user, authStatus, logoutUser} = useContext(AuthContext);
  const [menuElement, setMenuElement] = useState(null);
  const location = useLocation();
  const profileOnClick = (event) => {
    setMenuElement(event.currentTarget);
  };
  const menuClose = () => {
    setMenuElement(null);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: classes.navigationBarSpacing
  }), /* @__PURE__ */ React.createElement(AppBar, {
    elevation: 0
  }, /* @__PURE__ */ React.createElement(Toolbar, {
    className: classes.appBarToolBox
  }, /* @__PURE__ */ React.createElement(Link, {
    className: classes.homeAnchor,
    to: "/"
  }, /* @__PURE__ */ React.createElement(Box, {
    className: classes.leftArea
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/dist/assets/images/logo.png",
    alt: "",
    className: classes.logo
  }), /* @__PURE__ */ React.createElement(Typography, {
    variant: "h6",
    className: classes.title
  }, "EzioTAG"))), /* @__PURE__ */ React.createElement(Box, null, authStatus === "unauth" ? /* @__PURE__ */ React.createElement(Link, {
    to: "/login",
    className: classes.anchor
  }, /* @__PURE__ */ React.createElement(Button, {
    variant: "contained",
    disableElevation: true,
    className: classes.navbarButton
  }, "Login")) : null, authStatus === "auth" && location.pathname !== "/sessions" ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Link, {
    to: "/sessions",
    className: classes.anchor
  }, /* @__PURE__ */ React.createElement(Button, {
    variant: "contained",
    disableElevation: true,
    className: classes.navbarButton
  }, "My Sessions"))) : null, authStatus === "auth" ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Button, {
    onClick: profileOnClick
  }, /* @__PURE__ */ React.createElement(Avatar, {
    src: user.photoURL
  })), /* @__PURE__ */ React.createElement(Menu, {
    anchorEl: menuElement,
    open: Boolean(menuElement),
    onClose: menuClose,
    MenuListProps: {
      className: classes.userMenu
    }
  }, /* @__PURE__ */ React.createElement("img", {
    src: user.photoURL,
    className: classes.userImage,
    alt: ""
  }), /* @__PURE__ */ React.createElement(Typography, {
    className: classes.userEmail
  }, user.email), /* @__PURE__ */ React.createElement(Button, {
    onClick: logoutUser,
    variant: "contained",
    color: "primary",
    className: classes.logoutButton,
    disableElevation: true
  }, "Logout"))) : null))));
};
export default Navigation;
