import makeStyles from '../../../../_snowpack/pkg/@material-ui/core/styles/makeStyles.js';

const conditionalCSS = () =>
  makeStyles((theme) => ({
    loginContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
    subHeading: {
      fontWeight: 'bold',
      marginTop: 5,
    },
    smallInfo: {
      fontSize: 12,
      color: theme.palette.grey,
      textAlign: 'center',
      marginTop: 20,
    },
  }));

export default conditionalCSS;
